import { TCountryCode } from "countries-list";
import { Country } from "react-phone-number-input";
import { LanguageOptionDef } from "types/language.types";
import { mapLocaleToPhoneInputCountry } from "utils/language.helper";

export const LANGUAGES: LanguageOptionDef[] = [
  { languageCode: "de", countryCode: "DE" },
  { languageCode: "en", countryCode: "US" },
  { languageCode: "pl", countryCode: "PL" },
  { languageCode: "ro", countryCode: "RO" },
  { languageCode: "es", countryCode: "ES" },
  { languageCode: "fr", countryCode: "FR" },
  { languageCode: "it", countryCode: "IT" },
  { languageCode: "el", countryCode: "GR" },
  { languageCode: "cs", countryCode: "CZ" },
  { languageCode: "da", countryCode: "DK" },
  { languageCode: "pt-br", countryCode: "BR" },
  { languageCode: "tr", countryCode: "TR" },
  { languageCode: "uk", countryCode: "UA" },
  { languageCode: "sk", countryCode: "SK" },
  { languageCode: "hu", countryCode: "HU" },
  { languageCode: "nl", countryCode: "NL" },
  { languageCode: "sv", countryCode: "SE" },
  { languageCode: "nb", countryCode: "NO" },
  { languageCode: "hr", countryCode: "HR" },
  { languageCode: "ru", countryCode: "RU" },
];

export const LANGUAGES_PHONE = [
  ...LANGUAGES,
  { languageCode: "ch" },
  { languageCode: "at" },
  { languageCode: "be" },
]
  .map((lang) => mapLocaleToPhoneInputCountry(lang.languageCode))
  .filter(Boolean) as Country[];

export const STAT_LANGUAGES: LanguageOptionDef[] = [
  ...LANGUAGES,
  { languageCode: "pt-pt", countryCode: "PT" },
  { languageCode: "lb", countryCode: "LU" },
  { languageCode: "sl", countryCode: "SI" },
  { languageCode: "bg", countryCode: "BG" },
  { languageCode: "bs", countryCode: "BA" },
  { languageCode: "et", countryCode: "EE" },
  { languageCode: "fi", countryCode: "FI" },
  { languageCode: "hy", countryCode: "AM" },
  { languageCode: "is", countryCode: "IS" },
  { languageCode: "lt", countryCode: "LT" },
  { languageCode: "lv", countryCode: "LV" },
  { languageCode: "sq", countryCode: "AL" },
  { languageCode: "ar", countryCode: "AE" },
  { languageCode: "fa", countryCode: "IR" },
];

export const getLangName = (
  displayLang: string,
  langCode: string,
  type: Intl.DisplayNamesType = "language"
) => {
  try {
    return new Intl.DisplayNames([displayLang], {
      type,
      fallback: "code",
    }).of(langCode) as TCountryCode;
  } catch (error) {
    console.error("Failed to get language name", error);
    return langCode as TCountryCode;
  }
};
