import { functionsSetSession } from "@auth-session/api/auth.serverless.api";
import { editUserById, signUp } from "@auth/api/auth.api";
import {
  AuthFormDef,
  AuthSignUpFormDef,
  CreateEditMediaDef,
} from "@auth/types/auth.types";
import { createCertificate } from "@certificates/api/certificates.api";
import { CertificateRequest } from "@certificates/types/certificates.types";
import {
  mapSocialLinkToExternalLinks,
  uploadAndMapDocumentsToMedia,
} from "@profile/components/DocumentsUpload/DocumentsUploadForm/helpers/documents-upload.helper";
import { UserInfoFormDef } from "@profile/components/UserInfoForm/types/user-info-form.types";
import { fetchStats, updateStats } from "@stats/api/stats.api";
import { EJourneyTypes } from "constants/journey.constants";
import { CrafthuntStatDefRequest } from "types/crafthunt-profile.types";
import { CreateEditExternalLinkDef } from "types/external-links.types";
import { JobAdDef } from "types/job-ad.types";
import { triggerUserCreated } from "utils/analytics";
import { convertLocaleForBackend } from "utils/language.helper";
import { fetchGeoData } from "utils/services/google-ip-geolocation.service";

export const signUpNewUser = async (
  values: AuthFormDef,
  lang: string,
  url: string,
  jobAd?: JobAdDef,
  fromJourney?: EJourneyTypes,
  utmParams?: Record<string, string>
) => {
  const email = values.email || undefined;
  const phone = !email ? values.phone : undefined;
  const { country, city, lat, lng } = await fetchGeoData();
  const { workerProfile, accessToken } = await signUp({
    email,
    phone,
    name: values.name,
    password: values.password || undefined,
    lang: convertLocaleForBackend(lang),
    fromJourney,
    utmParams,
    jobAdId: jobAd?.id,
    ...((country || city) && {
      address: {
        city: city || undefined,
        country: country || undefined,
      },
    }),
    ...(lat &&
      lng && {
        coordinate: {
          lat: Number(lat),
          lng: Number(lng),
        },
      }),
  });
  triggerUserCreated({
    userId: workerProfile.id,
    name: values.name,
    email: workerProfile.email,
    phone: workerProfile.phone,
    url,
    locale: lang,
    fromJourney,
    jobAd,
  });
  // Set session in middleware
  await functionsSetSession({ accessToken });
  return {
    workerProfile,
    accessToken,
  };
};

export const onboardingEditUser = async (
  userId: string,
  values: AuthSignUpFormDef | UserInfoFormDef,
  locale: string
) => {
  let medias: CreateEditMediaDef[] = [];
  let externalLinks: CreateEditExternalLinkDef[] = [];
  if ("documents" in values) {
    medias = await uploadAndMapDocumentsToMedia(values.documents);
  }
  if ("socialLink" in values) {
    externalLinks = mapSocialLinkToExternalLinks(values.socialLink);
  }
  if ("educations" in values) {
    for (const certificateType of values.educations) {
      const data: CertificateRequest = {
        title: certificateType.name,
        typeId: certificateType.id,
      };
      await createCertificate(data);
    }
  }
  if ("travel" in values) {
    const stats = await fetchStats();
    await updateStats(stats.id, {
      travel: values.travel,
    } as CrafthuntStatDefRequest);
  }
  return editUserById(userId, {
    newPassword: values.password || undefined,
    lang: convertLocaleForBackend(locale),
    jobTitleId: values.role.name.id || undefined,
    profileTradesId: values.trades.data.map((item) => item.id) || undefined,
    preferredBenefitsId: values.benefits?.map((item) => item.id) || undefined,
    geoSearchAddress: {
      ...values.address.address,
      lat: values.address.lat,
      lng: values.address.lng,
      radius: values.address.radius,
    },
    medias: medias.length ? medias : undefined,
    externalLinks: externalLinks.length ? externalLinks : undefined,
  });
};
