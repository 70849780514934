import RoutesLoading from "@crafthunt-ui/RoutesLoading/RoutesLoading";
import "@crafthunt-ui/RoutesLoading/RoutesLoading.css";
import type { AnalyticsSnippet } from "@segment/analytics-next";
import { apiPlugin, storyblokInit } from "@storyblok/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { useMount } from "ahooks";
import Placeholder from "components/sections/Placeholder";
import { WebVitals } from "components/web-vitals/WebVitals";
import { dynamic_components } from "constants/dynamic-components.constants";
import { ENV, isProdOrStaging } from "constants/env";
import { MODAL_ID, TOOLTIP_ID } from "constants/ui.constants";
import { CandidateProvider } from "context/CandidateContext";
import { GoogleMapsProvider } from "context/GoogleMapsContext";
import { AuthModal } from "features/auth/components/AuthModal/AuthModal";
import { AuthProvider } from "features/auth/context/AuthContext";
import { AuthModalProvider } from "features/auth/context/AuthModalContext";
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import { firebaseConfig } from "firebaseConfig";
import { appWithTranslation } from "next-i18next";
import { AppProps } from "next/app";
import { Inter } from "next/font/google";
import { useRouter } from "next/router";
import posthog, { PostHogConfig } from "posthog-js";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { Toaster } from "react-hot-toast";
import { Tooltip } from "react-tooltip";
import Bugsnag from "utils/bugsnag";
import "../styles/tailwind.scss";

const inter = Inter({
  subsets: ["latin"],
  variable: "--font-inter",
  display: "swap",
});

initializeApp(firebaseConfig);

storyblokInit({
  accessToken: ENV.STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
  components: dynamic_components,
  apiOptions: {
    region: "eu",
  },
  enableFallbackComponent: true,
  customFallbackComponent: Placeholder,
});

declare global {
  interface Window {
    analytics: AnalyticsSnippet & PostHogConfig["segment"];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cookiebot: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    advPxl: any;
  }
}

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useMount(() => {
    const firebaseSignIn = async () => {
      const auth = getAuth();
      try {
        await signInAnonymously(auth);
      } catch (error) {
        console.error(error);
      }
    };

    firebaseSignIn();
  });

  useMount(() => {
    if (isProdOrStaging) {
      TagManager.initialize({ gtmId: ENV.GOOGLE_TAG_MANAGER_KEY });
      window.analytics?.ready(() => {
        posthog.init(ENV.POSTHOG_API_KEY, {
          api_host: "https://eu.i.posthog.com",
          segment: window.analytics,
          capture_pageview: false,
        });
        window.addEventListener("CookiebotOnDecline", () => {
          console.log("Opting out of capturing - decline");
          posthog.opt_out_capturing();
        });
        window.addEventListener("CookiebotOnAccept", () => {
          if (!window.Cookiebot?.consent?.statistics) {
            console.log("Opting out of capturing - accept but not statistics");
            posthog.opt_out_capturing();
          }
        });
      });
    }
  });

  useEffect(() => {
    const handleRouteChange = (url) => {
      window.analytics?.page(url);
    };

    router.events.on("routeChangeStart", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  });

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <style jsx global>{`
        :root {
          --font-inter: ${inter.style.fontFamily};
        }
      `}</style>
      <ErrorBoundary>
        <CandidateProvider>
          <AuthProvider initialAccessToken={pageProps.accessToken}>
            <GoogleMapsProvider>
              <AuthModalProvider>
                <WebVitals />
                <RoutesLoading />
                <Toaster />
                <Component {...pageProps} />
                <SpeedInsights />
                <div id={MODAL_ID} />
                <Tooltip id={TOOLTIP_ID} className="crafthunt-tooltip" />
                <AuthModal />
              </AuthModalProvider>
            </GoogleMapsProvider>
          </AuthProvider>
        </CandidateProvider>
      </ErrorBoundary>
    </>
  );
}

export default appWithTranslation(MyApp);
