import { ConditionalWrapper } from "@crafthunt-ui/ConditionalWrapper";
import { CustomLink } from "@crafthunt-ui/CustomLink/CustomLink";
import clsx from "clsx";
import NextImage from "next/image";
import { Fragment } from "react";
import { transformImage } from "utils/image.helper";
import { getFullSlug, getTranslatedSlug } from "utils/router.helper";

const LogoGrid = ({ blok, className = undefined, style = undefined }) => {
  return (
    <div
      className={clsx(
        "relative isolate pb-32 pt-24 sm:pt-24 bg-gray-50",
        className
      )}
      style={style}
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {!!blok.title && (
          <div className="mx-auto max-w-xl text-center">
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {blok.title}
            </h2>
          </div>
        )}
        <div className="mx-auto mt-12 grid max-w-2xl rounded-md grid-cols-2 gap-0.5 text-sm leading-6 text-gray-900 lg:grid-cols-3 lg:mx-0 lg:max-w-none  xl:grid-cols-4 overflow-hidden">
          {blok.logos.map((el) => (
            <Fragment key={el.id}>
              <ConditionalWrapper
                condition={!!el.source}
                wrapper={(children) => (
                  <CustomLink
                    id={`logo-image-${blok._uid}`}
                    href={getFullSlug(el.source)}
                    className="group"
                  >
                    {children}
                  </CustomLink>
                )}
              >
                <div className="relative h-28 flex justify-center items-center bg-white group-hover:bg-primary-100">
                  <NextImage
                    className="object-contain p-4 md:p-6"
                    src={transformImage(
                      el.filename,
                      "0x200/filters:quality(100)"
                    )}
                    alt={el.alt || "Company logo"}
                    fill
                    quality={100}
                    sizes="15vw"
                  />
                </div>
              </ConditionalWrapper>
            </Fragment>
          ))}
        </div>
        {/* Call to Action Section */}
        <div className="mx-auto max-w-7xl md:max-w-2xl lg:max-w-none text-left mt-10 rounded-md overflow-hidden">
          <div className="bg-white">
            <div className="mx-auto px-6 py-5 sm:py-8 lg:flex lg:items-center lg:justify-between lg:px-8">
              <div className="lg:mr-32">
                {!!blok.cta_title && (
                  <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                    {blok.cta_title}
                  </h2>
                )}
                {!!blok.cta_subtitle && <span>{blok.cta_subtitle}</span>}
              </div>
              {!!blok.cta_button_label && (
                <div className="mt-4 flex items-center gap-x-6 lg:mt-0 lg:shrink-0">
                  <CustomLink
                    isCTA
                    id={`cta-logo-grid-${blok._uid}`}
                    href={getTranslatedSlug(blok.cta_button_url, {
                      fromJourney: blok.cta_button_journey,
                    })}
                    target={blok.cta_button_url?.target}
                    className="rounded-sm bg-primary-600 px-3.5 py-2.5 text-lg font-medium text-white shadow-md hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {blok.cta_button_label}
                  </CustomLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoGrid;
